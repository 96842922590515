<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="170" @submit.native.prevent>
      <!-- prettier-ignore -->
      <Tabs type="card" name="modalMobileConfig" :animated="false">
        <TabPane tab="modalMobileConfig" name="basic" :label="this.$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <!-- companyId -->
            <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" :error="error.companyId" label="company" :disabled="isEdit || disabled" />
            <!-- mobileType -->
            <n-field-enum v-model="model.mobileType" field-name="mobileType" enum-name="MobileType" :error="error.mobileType" label="type" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- requestTimeout -->
            <n-field-number v-model="model.requestTimeout" field-name="requestTimeout" :error="error.requestTimeout" label="requestTimeout" :disabled="disabled" suffix-text="ms" />
            <!-- mobileVersion -->
            <n-field-text v-model="model.mobileVersion" field-name="mobileVersion" label="version" :error="error.mobileVersion" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- strictSubscription -->
            <n-field-boolean v-model="model.strictSubscription" field-name="strictSubscription" :error="error.strictSubscription" label="strictSubscription" :disabled="disabled" />
            <!-- sentryVerbose -->
            <n-field-boolean v-model="model.sentryVerbose" field-name="sentryVerbose" :error="error.sentryVerbose" label="sentryVerbose" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalMobileConfig" name="trading" :label="this.$t('label.trading')" class="n-modal-container">
          <n-row-col2>
            <!-- tradingIterationStep -->
            <n-field-number v-model="model.tradingIterationStep" field-name="tradingIterationStep" :error="error.tradingIterationStep" label="tradingIterationStep" :disabled="disabled" />
            <!-- tradingRefreshInterval -->
            <n-field-number v-model="model.tradingRefreshInterval" field-name="tradingRefreshInterval" :error="error.tradingRefreshInterval" label="tradingRefreshInterval" :disabled="disabled" suffix-text="ms" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalMobileConfig" name="pricing" :label="this.$t('label.pricing')" class="n-modal-container">
          <n-row-col2>
            <!-- chartIterationStep -->
            <n-field-number v-model="model.chartIterationStep" field-name="chartIterationStep" :error="error.chartIterationStep" label="chartIterationStep" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- chartInitialCapacity -->
            <n-field-number v-model="model.chartInitialCapacity" field-name="chartInitialCapacity" :error="error.chartInitialCapacity" label="chartInitialCapacity" :disabled="disabled" />
            <!-- chartMaximumCapacity -->
            <n-field-number v-model="model.chartMaximumCapacity" field-name="chartMaximumCapacity" :error="error.chartMaximumCapacity" label="chartMaximumCapacity" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalMobileConfig" name="connection" :label="this.$t('label.connection')" class="n-modal-container">
          <n-row-col2>
            <!-- heartbeatInterval -->
            <n-field-number v-model="model.heartbeatInterval" field-name="heartbeatInterval" :error="error.heartbeatInterval" label="heartbeatInterval" :disabled="disabled" suffix-text="ms" />
            <!-- maxConnectRetries -->
            <n-field-number v-model="model.maxConnectRetries" field-name="maxConnectRetries" label="maxConnectRetries" :error="error.maxConnectRetries" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- minConnectInterval -->
            <n-field-number v-model="model.minConnectInterval" field-name="minConnectInterval" :error="error.minConnectInterval" label="minConnectInterval" :disabled="disabled" suffix-text="ms" />
            <!-- maxConnectInterval -->
            <n-field-number v-model="model.maxConnectInterval" field-name="maxConnectInterval" :error="error.maxConnectInterval" label="maxConnectInterval" :disabled="disabled" suffix-text="ms" />
          </n-row-col2>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, edit, add } from '@/api/company/company-mobile-config';

export default NModal.extend({
  name: 'ModalCompanyMobileConfig',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      if (model.mobileVersion === '') {
        model.mobileVersion = undefined;
      }
      return validate(model);
    },
  },
});
</script>
