<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <!-- prettier-ignore -->
      <Tabs type="card" name="modalMobileRelease" :animated="false">
        <TabPane tab="modalMobileRelease" name="basic" :label="this.$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <!-- mobileType -->
            <n-field-enum v-model="model.mobileType" field-name="mobileType" enum-name="MobileType" :error="error.mobileType" label="type" :disabled="isEdit || disabled" />
            <!-- mobileVersion -->
            <n-field-text v-model="model.mobileVersion" field-name="mobileVersion" label="version" :error="error.mobileVersion" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- companyId -->
            <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" :error="error.companyId" label="company" :disabled="isEdit || disabled" />
            <!-- visibility -->
            <n-field-percent v-model="model.visibility" :scale="2" format="0 %" field-name="visibility" label="visibility" :error="error.visibility" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- releaseTime -->
            <n-field-datetime v-model="model.releaseTime" field-name="releaseTime" label="releaseTime" :error="error.releaseTime" :disabled="disabled" />
            <!-- releaseStatus -->
            <n-field-enum v-model="model.releaseStatus" field-name="releaseStatus" enum-name="MobileReleaseStatus" :error="error.releaseStatus" label="releaseStatus" :disabled="disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalMobileRelease" name="meta" :label="this.$t('label.meta')" class="n-modal-container">
          <n-row-col2>
            <!-- systemProtoVersion -->
            <n-field-text v-model="model.systemProtoVersion" field-name="systemProtoVersion" :error="error.systemProtoVersion" label="systemProtoVersion" :disabled="isEdit || disabled" />
            <!-- marketProtoVersion -->
            <n-field-text v-model="model.marketProtoVersion" field-name="marketProtoVersion" :error="error.marketProtoVersion" label="marketProtoVersion" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- companyProtoVersion -->
            <n-field-text v-model="model.companyProtoVersion" field-name="companyProtoVersion" :error="error.companyProtoVersion" label="companyProtoVersion" :disabled="isEdit || disabled" />
            <!-- pricingProtoVersion -->
            <n-field-text v-model="model.pricingProtoVersion" field-name="pricingProtoVersion" :error="error.pricingProtoVersion" label="pricingProtoVersion" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- customerProtoVersion -->
            <n-field-text v-model="model.customerProtoVersion" field-name="customerProtoVersion" :error="error.customerProtoVersion" label="customerProtoVersion" :disabled="isEdit || disabled" />
            <!-- tradingProtoVersion -->
            <n-field-text v-model="model.tradingProtoVersion" field-name="tradingProtoVersion" :error="error.tradingProtoVersion" label="tradingProtoVersion" :disabled="isEdit || disabled" />
          </n-row-col2>
        </TabPane>
        <TabPane tab="modalMobileRelease" name="message" :label="this.$t('label.message')" class="n-modal-container">
          <n-row-col1>
            <!-- url -->
            <n-field-text v-model="model.url" field-name="url" :error="error.url" label="url" :disabled="disabled" />
            <!-- title -->
            <n-field-text v-model="model.title" field-name="title" :error="error.title" label="title" :disabled="disabled" />
          </n-row-col1>
          <n-row-col1>
            <!-- content -->
            <n-field-textarea v-model="model.content" :rows="3" field-name="content" :error="error.content" label="content" :disabled="disabled" style="padding-bottom: 10px" />
          </n-row-col1>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, edit, add } from '@/api/company/company-mobile-release';

export default NModal.extend({
  name: 'ModalCompanyMobileRelease',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      if (model.mobileVersion === '') {
        model.mobileVersion = undefined;
      }

      if (model.systemProtoVersion === '') {
        model.systemProtoVersion = undefined;
      }

      if (model.marketProtoVersion === '') {
        model.marketProtoVersion = undefined;
      }

      if (model.companyProtoVersion === '') {
        model.companyProtoVersion = undefined;
      }

      if (model.pricingProtoVersion === '') {
        model.pricingProtoVersion = undefined;
      }

      if (model.customerProtoVersion === '') {
        model.customerProtoVersion = undefined;
      }

      if (model.tradingProtoVersion === '') {
        model.tradingProtoVersion = undefined;
      }
      return validate(model);
    },
  },
});
</script>
