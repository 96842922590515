import { $fetch, $post, $put, $delete } from '@/services/axios';

export const CSV_URL = `company/mobile/releases/csv`;

export function add(data) {
  return $put(`company/mobile/release`, data);
}

export function fetch(data) {
  return $fetch(`company/mobile/releases`, data);
}

export function validate(data) {
  return $post(`company/mobile/release/validate`, data);
}

export function edit(data) {
  return $post(`company/mobile/release/${data.id}`, data);
}

export function reset(data) {
  return $post(`company/mobile/release/reset/${data.id}`);
}

export function logout(data) {
  return $post(`company/mobile/release/logout/${data.id}`);
}

export function remove(data) {
  return $delete(`company/mobile/release/${data.id}/${data.version}`);
}

export function latest(data) {
  return $fetch(`company/mobile/release/latest`, data);
}
