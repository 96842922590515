<template>
  <index-content>
    <Tabs v-model="tab" name="companyMobile" :animated="false" @on-click="changeTab">
      <template #extra>
        <div class="n-button-box">
          <nb-download v-if="tab === CONFIG" :url="CONFIG_CSV_URL" :params="configCondition" />
          <nb-download v-if="tab === RELEASE" :url="RELEASE_CSV_URL" :params="releaseCondition" />
          <nb-add @on-click="openAddModal" />
        </div>
      </template>
      <TabPane tab="companyMobile" :name="CONFIG" :label="this.$t('label.config')" class="pt-2">
        <search-condition>
          <ns-company v-model="configCondition.companyId" @on-change="load" />
          <n-field-enum v-model="configCondition.mobileType" enum-name="MobileType" label="type" @on-change="load" />
        </search-condition>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover text-center n-table table-config">
            <thead>
              <tr>
                <th class="col-weight-1">{{ $t('label.company') }}</th>
                <th class="col-weight-1">{{ $t('label.type') }}</th>
                <th class="col-weight-1">{{ $t('label.version') }}</th>
                <th class="col-weight-2">{{ $t('label.requestTimeout') }}</th>
                <th class="col-weight-2">{{ $t('label.heartbeatInterval') }}</th>
                <th class="col-weight-3">{{ $t('label.maxConnectRetries') }}</th>
                <th v-table-action>{{ $t('label.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in records" :key="row.id">
                <td v-selection:[`company.company`]="row.companyId"></td>
                <td v-enums:MobileType="row.mobileType"></td>
                <td>{{ row.mobileVersion }}</td>
                <td class="n-text-right">
                  <n-number :value="row.requestTimeout" unit="ms" />
                </td>
                <td class="n-text-right">
                  <n-number :value="row.heartbeatInterval" unit="ms" />
                </td>
                <td class="n-text-right">
                  <n-number :value="row.maxConnectRetries" />
                </td>
                <td class="n-button-box">
                  <nb-modal @on-click="openModal(row)"></nb-modal>
                  <nb-confirm text="remove" @on-ok="doRemove(row)"></nb-confirm>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TabPane>
      <TabPane tab="companyMobile" :name="RELEASE" :label="this.$t('label.release')" class="pt-2">
        <search-condition>
          <ns-company v-model="releaseCondition.companyId" @on-change="load" />
          <n-field-enum v-model="releaseCondition.mobileType" enum-name="MobileType" label="type" @on-change="load" />
        </search-condition>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover text-center n-table table-release">
            <thead>
              <tr>
                <th class="col-weight-10">{{ $t('label.company') }}</th>
                <th class="col-weight-10">{{ $t('label.type') }}</th>
                <th class="col-weight-10">{{ $t('label.version') }}</th>
                <th class="col-weight-10">{{ $t('label.visibility') }}</th>
                <th class="col-weight-10">{{ $t('label.status') }}</th>
                <th class="col-weight-37">{{ $t('label.title') }}</th>
                <th class="n-table-timestamp">{{ $t('label.releaseTime') }}</th>
                <th v-table-action>{{ $t('label.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in records" :key="row.id">
                <td v-selection:[`company.company`]="row.companyId"></td>
                <td v-enums:MobileType="row.mobileType"></td>
                <td>{{ row.mobileVersion }}</td>
                <td v-percentage="{ value: row.visibility, pattern: '0 %' }" class="n-text-right"></td>
                <td v-enums:MobileReleaseStatus="row.releaseStatus"></td>
                <td class="n-ellipsis" :title="row.title">{{ row.title }}</td>
                <td>{{ row.releaseTime | datetime }}</td>
                <td class="n-button-box">
                  <nb-modal @on-click="openModal(row)"></nb-modal>
                  <nb-confirm
                    :disabled="row.releaseStatus === $enums.MobileReleaseStatus.ACTIVE"
                    text="remove"
                    @on-ok="doRemove(row)"
                  ></nb-confirm>
                  <nb-dropdown v-rw :items="items" @on-click="k => (k === 'reset' ? onReset(row) : onLogout(row))"></nb-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TabPane>
    </Tabs>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import ModalCompanyMobileConfig from './ModalCompanyMobileConfig';
import ModalCompanyMobileRelease from './ModalCompanyMobileRelease';
import {
  fetch as fetchConfig,
  latest as latestConfig,
  remove as removeConfig,
  CSV_URL as CONFIG_CSV_URL,
} from '@/api/company/company-mobile-config';
import {
  reset,
  logout,
  fetch as fetchRelease,
  latest as latestRelease,
  remove as removeRelease,
  CSV_URL as RELEASE_CSV_URL,
} from '@/api/company/company-mobile-release';

const CONFIG = 'config';
const RELEASE = 'release';
export default BaseCompanyView.extend({
  name: 'CompanyMobile',

  data() {
    return {
      CONFIG,
      RELEASE,
      CONFIG_CSV_URL,
      RELEASE_CSV_URL,
      tab: 'config',
      preload: true,
      configCondition: {
        companyId: 0,
        mobileType: this.$enums.MobileType.IOS,
      },
      releaseCondition: {
        companyId: 0,
        mobileType: this.$enums.MobileType.IOS,
      },
      items: [{ key: 'reset', text: 'reset' }, { key: 'logout', text: 'logout' }],
    };
  },

  methods: {
    onReset(o) {
      reset(o);
    },

    onLogout(o) {
      logout(o);
    },

    changeTab(tab) {
      this.records = [];
      this.load();
    },

    parse(data) {
      this.records = data;
    },

    doLoad() {
      if (this.tab === CONFIG) {
        return fetchConfig({ ...this.configCondition });
      } else {
        return fetchRelease({ ...this.releaseCondition });
      }
    },

    openAddModal() {
      // this.visible = true;
      if (this.tab === CONFIG) {
        latestConfig({ ...this.configCondition }).then(prev => {
          if (prev) {
            prev.id = undefined;
            this.record = prev;
          } else {
            this.record = {};
          }
          this.createModal(ModalCompanyMobileConfig, { model: this.record, props: { subName: 'mobileConfig' }, on: this });
        });
      } else {
        latestRelease({ ...this.releaseCondition }).then(prev => {
          if (prev) {
            let { url, title, content } = prev;
            this.record = Object.assign({}, { ...this.releaseCondition }, { url, title, content });
          } else {
            this.record = {};
          }
          this.createModal(ModalCompanyMobileRelease, { model: this.record, props: { subName: 'mobileRelease' }, on: this });
        });
      }
    },

    openModal(model) {
      if (this.tab === CONFIG) {
        this.createModal(ModalCompanyMobileConfig, { model, props: { subName: 'mobileConfig' }, on: this });
      } else {
        this.createModal(ModalCompanyMobileRelease, { model, props: { subName: 'mobileRelease' }, on: this });
      }
    },

    doRemove(o) {
      if (this.tab === CONFIG) {
        return removeConfig(o);
      } else {
        return removeRelease(o);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  &.table-config th.rw-action {
    width: 9.3rem;
  }

  &.table-release th.rw-action {
    width: 13.5rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
