import { $fetch, $post, $put, $delete } from '@/services/axios';

export const CSV_URL = `company/mobile/configs/csv`;

export function add(data) {
  return $put(`company/mobile/config`, data);
}

export function fetch(data) {
  return $fetch(`company/mobile/configs`, data);
}

export function validate(data) {
  return $post(`company/mobile/config/validate`, data);
}

export function edit(data) {
  return $post(`company/mobile/config/${data.id}`, data);
}

export function remove(data) {
  return $delete(`company/mobile/config/${data.id}/${data.version}`);
}

export function latest(data) {
  return $fetch(`company/mobile/config/latest`, data);
}
